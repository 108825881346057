import { useEffect, useState } from 'react'
import axios from 'axios'
import { TextField, Box, Typography, Button, styled } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'
import { navigate } from 'gatsby'

const StyledBox = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        marginTop: '0rem',
        padding: '1rem',
      },
    }
  }}
  display: flex;
  margin-top: 8rem;
  align-items: center;
  flex-direction: column;
`

const CompleteProfile = () => {
  const { user, getAccessTokenSilently } = useAuth0()
  const [formData, setformData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (user) {
      if (user.given_name && user.family_name) {
        navigate('/')
      }
    }
  }, [user])

  const isValid = () => {
    if (!formData.firstName || !formData.lastName) {
      toast.error('Please input all fields.')
      return false
    }

    return true
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setformData({
      ...formData,
      [e.target.name]: value,
    })
  }

  const handleSubmit = async () => {
    if (isValid()) {
      setIsLoading(true)
      const token = await getAccessTokenSilently()
      const domain = process.env.AUTH0_DOMAIN ?? 'dev-tritag.au.auth0.com'

      const options = {
        method: 'PATCH',
        url: `https://${domain}/api/v2/users/${user.sub}`,
        headers: {
          // this is only a test token
          authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        data: {
          given_name: formData.firstName,
          family_name: formData.lastName,
        },
      }

      await axios
        .request(options)
        .then(() => {
          toast.success('Profile completed succesfully.')
          window.location.href = '/'
          setIsLoading(false)
        })
        .catch(() => {
          toast.error('Something went wrong please try again.')
          setIsLoading(false)
        })
    }
  }

  return (
    <StyledBox>
      <div>
        <div>
          <img
            src="https://tritagrugbypublic.s3.ap-southeast-2.amazonaws.com/logo.png"
            alt="logo"
            style={{
              maxWidth: 150,
              marginBottom: '3rem',
            }}
          />
        </div>
        <Typography variant="h4" style={{ marginBottom: '2rem' }}>
          Complete your profile
        </Typography>

        <div>
          <TextField
            required
            id="filled-required"
            label="First Name"
            style={{ width: '100%', marginBottom: '1rem' }}
            name="firstName"
            onChange={handleOnChange}
            value={formData.firstName}
          />
          <TextField
            required
            id="filled-required"
            label="Last Name"
            style={{ width: '100%' }}
            name="lastName"
            onChange={handleOnChange}
            value={formData.lastName}
          />

          <div style={{ textAlign: 'center', marginTop: '5rem' }}>
            <Button
              variant="contained"
              color="primary"
              className="montserrat"
              style={{ margin: 'auto' }}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              COMPLETE PROFILE
            </Button>
          </div>
        </div>
      </div>
    </StyledBox>
  )
}

export default CompleteProfile
